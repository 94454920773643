define("discourse/plugins/automation/admin/controllers/admin-plugins-discourse-automation", ["exports", "@ember/controller", "@ember/object", "@ember/service"], function (_exports, _controller, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Automation extends _controller.default {
    static #_ = dt7948.g(this.prototype, "router", [_service.inject]);
    #router = (dt7948.i(this, "router"), void 0);
    get showNewAutomation() {
      return this.router.currentRouteName === "adminPlugins.discourse-automation.index";
    }
    static #_2 = dt7948.n(this.prototype, "showNewAutomation", [(0, _object.computed)("router.currentRouteName")]);
    newAutomation() {
      this.router.transitionTo("adminPlugins.discourse-automation.new");
    }
    static #_3 = dt7948.n(this.prototype, "newAutomation", [_object.action]);
  }
  _exports.default = Automation;
});