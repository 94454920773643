define("discourse/plugins/automation/admin/routes/admin-plugins-discourse-automation-index", ["exports", "@ember/object", "discourse/routes/discourse"], function (_exports, _object, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AutomationIndex extends _discourse.default {
    controllerName = "admin-plugins-discourse-automation-index";
    model() {
      return this.store.findAll("discourse-automation-automation");
    }
    triggerRefresh() {
      this.refresh();
    }
    static #_ = dt7948.n(this.prototype, "triggerRefresh", [_object.action]);
  }
  _exports.default = AutomationIndex;
});